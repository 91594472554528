export const getFingerprintData = (fingerprintData) => {
  return {
    type: "getFingerprint",
    payload: fingerprintData,
  };
};

export const createFingerprint = (data) => {
  return {
    type: "createFingerprint",
    payload: data,
  };
};

export const updateFingerprintData = (fingerprintData) => {
  return {
    type: "updateCart",
    payload: fingerprintData,
  };
};
